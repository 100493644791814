<div class="select-bar">
  <div class="select-bar-item">
    <mat-form-field appearance="fill">
      <mat-label>Project name</mat-label>
      <mat-select [(value)]="newSelectsData.project">
        <mat-option *ngFor="let project of projects" [value]="project" (click)="onChange('projects',project)">
          {{project}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="select-bar-item">
    <mat-form-field appearance="fill">
      <mat-label>Message type</mat-label>
      <mat-select [(value)]="newSelectsData.type">
        <mat-option *ngFor="let type of types" [value]="type" (click)="onChange('types',type)">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="select-bar-item">
    <mat-form-field appearance="fill">
      <mat-label>Service name</mat-label>
      <mat-select [(value)]="newSelectsData.service">
        <mat-option *ngFor="let service of services" [value]="service" (click)="onChange('services',service)">
          {{service}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="select-bar-item">
    <mat-form-field appearance="fill">
      <mat-label>Box ID</mat-label>
      <input [defaultValue]="newSelectsData.boxId" matInput placeholder="Box ID" type="text" #inputBoxId (input)="onKeyUp('boxId', inputBoxId.value)" >
    </mat-form-field>
  </div>

  <div class="select-bar-item">
    <mat-form-field appearance="fill">
      <mat-label>Folder ID</mat-label>
      <input [defaultValue]="newSelectsData.folderId" matInput placeholder="Folder ID" type="text" #inputFolderId (input)="onKeyUp('folderId', inputFolderId.value)">
    </mat-form-field>
  </div>
  
  <div class="select-bar-item">
    <mat-form-field appearance="fill">
      <mat-label>Search message</mat-label>
      <textarea [defaultValue]="newSelectsData.message || ''" matInput placeholder="Message" type="text" #inputMessage (input)="onKeyUp('message', inputMessage.value)"></textarea>
    </mat-form-field>
  </div>

  <div class="select-bar-item">
    <mat-form-field>
      <input matInput [ngxMatDatetimePicker]="startpicker" 
                      placeholder="Choose start datetime" 
                      [disabled]="false"
                      [formControl]="startDate"
                      (dateChange)="onChange('dateStart', $event.value)"
                      autocomplete="off"
                      >
      <mat-datepicker-toggle matSuffix [for]="$any(startpicker)">
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix (click)="clearStartDate()">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #startpicker 
        [showSpinners]="true" 
        [showSeconds]="false"
        [stepHour]="1" [stepMinute]="1" 
        [stepSecond]="1"
        [touchUi]="false"
        [color]="'primary'"
        >
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </div>

  <div class="select-bar-item">
    <mat-form-field>
      <input matInput [ngxMatDatetimePicker]="endpicker" 
                      placeholder="Choose end datetime" 
                      [disabled]="false"
                      [formControl]="endDate"
                      (dateChange)="onChange('dateEnd', $event.value)"
                      autocomplete="off"
                      >
      <mat-datepicker-toggle matSuffix [for]="$any(endpicker)">
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix (click)="clearEndDate()">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #endpicker 
        [showSpinners]="true" 
        [showSeconds]="false"
        [stepHour]="1" [stepMinute]="1" 
        [stepSecond]="1"
        [touchUi]="false"
        [color]="'primary'">
        
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </div>
</div>