import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: './no-rows-overlay.component.html',
  styleUrls: ['./no-rows-overlay.component.scss']
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {

  private params!: INoRowsOverlayParams;
  
  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }

}
