import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CellClickedEvent, Context, CsvExportParams, GridApi, GridOptions, ProcessCellForExportParams, SelectionChangedEvent, ValueGetterParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NoRowsOverlayComponent } from './no-rows-overlay/no-rows-overlay.component';
import { GridDatasourceService } from './services/gridDatasource/grid-datasource.service';
import { SelectsService } from './services/selectService/selects.service';
import { environment } from '../environments/environment.prod';
import { ProjectAndTypesService } from './services/projectandTypes/project-and-types.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private gridApi!: GridApi;
  public gridOptions: GridOptions;
  public enableCSVButton: boolean;
  public isSidebarOpen: boolean;
  public isHandset$: Observable<boolean>;
  public grid_theme: string;
  public pageTheme: string;

  @HostBinding('class') className = '';
  public toggleControl: FormControl;

  // project: string;
  // service: string;
  // type: string;
  // boxId: string;
  // folderid: string;
  // message: string;

  constructor(
    public gridDataSource: GridDatasourceService,
    private selectsService: SelectsService,
    private breakpointObserver: BreakpointObserver,
    private clipboard: Clipboard,
    public overlay: OverlayContainer,
    public projectsService: ProjectAndTypesService
    ) {

    this.enableCSVButton = false;
    this.isSidebarOpen = true;
    this.grid_theme = 'ag-theme-alpine';
    this.pageTheme = localStorage.getItem('theme') || 'light';
    this.toggleControl = new FormControl();

    this.gridOptions = {
      columnDefs: [
        {
          headerName: 'Date',
          field: 'DateTime',
        },
        {
          headerName: 'Type',
          field: 'Type',
        },
        {
          headerName: 'Message',
          field: 'Subject',
          onCellClicked: (event: CellClickedEvent) => this.showMessage(event),
        },
        {
          headerName: 'Project',
          field: 'Project',
        },
        {
          headerName: 'Service',
          field: 'Service',
        },
        {
          headerName: 'Cif ID',
          field: 'CifId',
        },
        {
          headerName: 'MailFilePdfName',
          field: 'MailFilePdfName',
        },
        {
          headerName: 'Box ID',
          field: 'BoxId',
          onCellClicked: (event: CellClickedEvent) => this.copyToClipboard(event)
        },
        {
          headerName: 'Folder ID',
          field: 'FolderId',
          onCellClicked: (event: CellClickedEvent) => this.copyToClipboard(event)
        }
      ],
      defaultColDef: {
        editable: false,
        sortable: false,
        resizable: true,
        suppressSizeToFit: false
      },
      frameworkComponents: {
        'noRowsOverlay': NoRowsOverlayComponent
      },
      
      rowModelType: 'infinite',
      rowSelection: 'multiple',
      rowMultiSelectWithClick: false,
      animateRows: true,
      cacheBlockSize: 2000,
      paginationPageSize: environment.paginationSize,
      noRowsOverlayComponent: 'noRowsOverlay',
      onGridSizeChanged: () => this.fitGrid(),
      context: {
        loadInitialData: true
      }
    }

    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
        map(result => result.matches),
        shareReplay()
      );
  }

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((darkMode) => {
      const darkClassName = 'darkMode';
      this.className = darkMode ? darkClassName : '';
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(darkClassName);
        this.grid_theme = 'ag-theme-alpine-dark';
        this.pageTheme = 'dark';
      } else {
        this.overlay.getContainerElement().classList.remove(darkClassName);
        this.grid_theme = 'ag-theme-alpine';
        this.pageTheme = 'light';
      }

      localStorage.setItem('theme', this.pageTheme);
    });
  }

  fitGrid() {
    this.gridApi.sizeColumnsToFit();
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    let selectedRows = event.api.getSelectedRows();
    this.enableCSVButton = selectedRows.length > 0 ? true : false;
  }

  deselectAll() {
    this.gridApi.deselectAll();
  }

  processCell(params: any) {
    let cellValue;
    params.accumulatedRowIndex == 2
      ? cellValue = params.node.data.Message.value
      : cellValue = params.value
    return cellValue;
  }

  exportToCSV() {
    const exportParams: CsvExportParams = {
      onlySelected: true,
      fileName: `${new Date().toDateString()}.csv`,
      processCellCallback: this.processCell
    }

    this.gridApi.exportDataAsCsv(exportParams);
  }

  exportAllToCSV() {
    const exportParams: CsvExportParams = {
      allColumns: true,
      fileName: `${new Date().toDateString()}.csv`,
      processCellCallback: this.processCell
    }

    this.gridApi.exportDataAsCsv(exportParams);
  }

  copyToClipboard(event: CellClickedEvent) {
    event.value
      &&
      Swal.fire({
        text: 'Copied to clipboard',
        icon: 'success',
        timer: 1300,
        backdrop: false,
        showConfirmButton: false,
        didOpen: () => {
          let value;

          if (event.column.getColId() === 'Subject')
            value = event.data.Message.value;
          else
            value = event.value;

          this.clipboard.copy(value);
        }
      })
  }

  showMessage(event: CellClickedEvent) {

    const { Subject, Message, Type } = event.data;

    Swal.fire({
      title: Subject,
      text: Message.value,
      width: '75%',
      icon: Type.toLowerCase(),
      backdrop: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Copy to clipboard',
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.copyToClipboard(event);
      }
    })
  }

  onGridReady(params: any) {
    this.gridApi = params.api;

    this.selectsService.getSelectsData().subscribe(data => {
      this.gridOptions.context = { ...this.gridOptions.context, ...data, apiRef: params.api }
      this.gridApi.setDatasource(this.gridDataSource);
    });

    const isDarkModeEnabled = this.pageTheme === 'light' ? false : true;
    this.toggleControl.setValue(isDarkModeEnabled);
  }

}
