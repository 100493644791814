<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-nav-list>
            <div *ngIf="gridDataSource.totalRows != -1" class="rowCount">
                <small>Showing {{gridDataSource.rowCount}} of {{gridDataSource.totalRows}} entries</small>
            </div>

            <app-select-bar></app-select-bar>

            <div class="main-buttons">
                <button class="main-page-button" mat-raised-button color="primary" (click)="exportAllToCSV()">
                    Export all to csv
                </button>

                <button *ngIf="enableCSVButton" class="main-page-button" mat-raised-button color="primary"
                    (click)="exportToCSV()">
                    Export selected to csv
                </button>

                <button *ngIf="enableCSVButton" class="main-page-button" mat-raised-button color="warn"
                    (click)="deselectAll()">
                    Deselect all
                </button>
            </div>

            <mat-slide-toggle [formControl]="toggleControl">
                <div *ngIf="pageTheme === 'light'; then lightOn else lightOff"></div>
                <ng-template #lightOn>
                    <mat-icon>nightlight_round</mat-icon>
                </ng-template>
                <ng-template #lightOff>
                    <mat-icon>highlight</mat-icon>
                </ng-template>
            </mat-slide-toggle>

        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <button class="menu-button-toggle" color="primary" (click)="drawer.toggle(); isSidebarOpen = !isSidebarOpen">
            <div *ngIf="isSidebarOpen; then left else right"></div>
            <ng-template #right>
                <mat-icon aria-hidden="false">chevron_right</mat-icon>
            </ng-template>
            <ng-template #left>
                <mat-icon aria-hidden="false">chevron_left</mat-icon>
            </ng-template>
        </button>

        <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="not-grid" [class]="grid_theme"
            [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
            (selectionChanged)="onSelectionChanged($event)" *ngIf="this.projectsService.projects.length > 0">
        </ag-grid-angular>

    </mat-sidenav-content>
</mat-sidenav-container>