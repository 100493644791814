import { Injectable } from '@angular/core';
import { Grid, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProjectAndTypesService } from '../projectandTypes/project-and-types.service';
import { environment } from 'src/environments/environment';
import { GridData } from 'src/app/interfaces/gridData';
import { gridInfo } from '../../interfaces/gridData';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GridDatasourceService implements IDatasource {

  private cursor: string;
  private draw: number;
  public rowCount: number;
  public totalRows: number;

  constructor(private http: HttpClient,
    private projectAndTypes: ProjectAndTypesService) {
    this.cursor = '';
    this.draw = 0;
    this.rowCount = 0;
    this.totalRows = 0;
  }

  getIdFromProject(project: string): string {
    let id = '';

    this.projectAndTypes.projects.forEach((value: [string, unknown]) => {
      if (value[1] == project)
        id = value[0];
    });

    return id;
  }

  getIdFromType(type: string): string {
    let id = '';


    this.projectAndTypes.types.forEach((value: [string, unknown]) => {
      if (value[1] === type) {
        id = value[0];
      }
    });

    return id;
  }

  getIdFromService(service: string): string {
    let id = '';

    this.projectAndTypes.services.forEach((value: [string, unknown]) => {
      if (value[1] == service)
        id = value[0];
    });

    return id;
  }



  createHttpParams(params: Map<string, any>): HttpParams {

    let httpParams = new HttpParams();
    params.forEach((value: any, key: string) => {
      httpParams = httpParams.append(key, value);
    });

    return httpParams;
  }

  formatDate(date: Date): string {
    return moment(date).format("YYYY-MM-DD").toString();
  }




  createParametres(params: IGetRowsParams): Map<string, any> {

    const { boxId, folderId, date, project, service, type } = params.context;
    const { start: dateStart, end: dateEnd } = date;

    let parametres = new Map();
    let idProject = this.getIdFromProject(project);
    let idType = this.getIdFromType(type);
    let idService = this.getIdFromService(service);


    parametres.set('project', idProject);
    parametres.set('folderIdSearch', folderId);
    parametres.set('boxIdSearch', boxId);
    parametres.set('typeSearch', idType);
    parametres.set('serviceSearch', idService);
    parametres.set('dateStartSearch', dateStart);
    parametres.set('dateEndSearch', dateEnd);
    parametres.set('startCursor', this.cursor);
    parametres.set('paginationSize', environment.paginationSize)
    parametres.set('draw', this.draw);


    return parametres;
  }


  filterMessage(res: GridData, message: string) {
    res.data = res.data.filter(
      (entry: gridInfo) => {
        let value = entry.Message.value;
        let subject = entry.Subject;
        value = value.toLowerCase();
        subject = subject.toLowerCase();
        message = message.toLowerCase().trim();
        return value.includes(message) || subject.includes(message);
      });
  }

  formatDateData(res: GridData) {
    res.data.forEach((item: gridInfo) => {

      var utcSeconds: number = Number(item.DateTime);
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      item.DateTime = moment(d).local().format("DD-MM-YYYY kk:mm:ss");
    });
  }

  saveLastQuery(params: HttpParams) {
    params.set('startCursor', '');
    localStorage.setItem('lastQuery', params.toString());
  }

  loadLastQuery(params: HttpParams) {
    const lastQuery: string = localStorage.getItem('lastQuery') || '';

    if (lastQuery !== '')
      params = new HttpParams({ fromString: lastQuery })
  }


  async getRows(params: IGetRowsParams): Promise<void> {

    const { startRow, context } = params;
    const { loadInitialData } = context;

    if (startRow == 0) {
      this.cursor = '';
      this.rowCount = 0;
    }

    let parametres = this.createParametres(params);
    let httpParams = this.createHttpParams(parametres);


    if (loadInitialData && localStorage.getItem('lastQuery') !== null) {
      this.loadLastQuery(httpParams);
      params.context.loadInitialData = false;
    }

    this.saveLastQuery(httpParams);

    return await this.http
      .get<GridData>(environment.url + 'ag-messages', { params: httpParams })
      .toPromise()
      .then((res: GridData) => {
        
        
        this.cursor = res.cursor;
        var lastRow = -1;

        const { message } = params.context;

        if (message !== '' && message !== undefined) {
          this.filterMessage(res, message);
        }

        res.data.length === 0
          ? params.context.apiRef?.showNoRowsOverlay()
          : params.context.apiRef?.hideOverlay();

        this.rowCount += res.data.length;

        if (res.data.length < environment.paginationSize)
          lastRow = this.rowCount;

        this.draw++;
        this.totalRows = parseInt(res.totalEntities);
        this.formatDateData(res);
        params.context.apiRef?.deselectAll();
        params.successCallback(res.data, lastRow);

      })
      .catch(err => {
        console.log(err);
        params.failCallback()
      })
  }

}
