import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectAndTypesService{


  public projects: [string, unknown][];
  public types: [string, unknown][];
  public services: [string, unknown][];

  constructor(private http: HttpClient) {
    this.projects = [];
    this.types = [];
    this.services = [];
  }
   

   //Upload projects, services and types from the datastore
  uploadFromDataStore() : Observable<Object>{
    return this.http.get(environment.url + 'getprojectsandtypes');
  }
  
}
