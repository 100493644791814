import { Component, OnInit } from '@angular/core';
import { selectsData } from '../interfaces/selectsData';
import { ProjectAndTypesService } from '../services/projectandTypes/project-and-types.service';
import { SelectsService } from '../services/selectService/selects.service';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-select-bar',
  templateUrl: './select-bar.component.html',
  styleUrls: ['./select-bar.component.scss']
})
export class SelectBarComponent implements OnInit {

  public newSelectsData!: selectsData;
  private timeout: any;
  public datePickerGroup!: FormGroup;
  public projects: string[];
  public types: string[];
  public services: string[];
  public startDate!: FormControl;
  public endDate!: FormControl;

  constructor(
    private projectAndTypes: ProjectAndTypesService,
    private selectService: SelectsService) {

    this.projects = [];
    this.types = [];
    this.services = [];

    selectService.getSelectsData()
      .subscribe((data: selectsData) => {
        this.newSelectsData = data;
      });


    const { date } = this.newSelectsData;

    date.start.length !== 0
      ? this.startDate = new FormControl(moment.unix(parseInt(date.start)))
      : this.startDate = new FormControl(moment().subtract(5, 'days').toDate());

    date.end.length != 0
      ? this.endDate = new FormControl(moment.unix(parseInt(date.end)))
      : this.endDate = new FormControl();

    this.datePickerGroup = new FormGroup({
      start: this.startDate,
      end: this.endDate
    });

    this.timeout = 1000;
  }

  ngOnInit() {
    this.projectAndTypes.uploadFromDataStore().subscribe((data: any) => {

      this.projectAndTypes.projects = Object.entries(data.projects);
      this.projects = Object.values(data.projects);
      this.projects.sort();
      this.projects.unshift('All');


      this.projectAndTypes.types = Object.entries(data.types);
      this.types = Object.values(data.types);
      this.types.sort();
      this.types.unshift('All');

      this.projectAndTypes.services = Object.entries(data.services);
      this.services = Object.values(data.services);
      this.services.sort();
      this.services.unshift('All');
    });
  }

  clearStartDate() {
    this.startDate.reset();
    this.newSelectsData.date.start = "";
    this.selectService.updateSelectsData(this.newSelectsData);
  }

  clearEndDate() {
    this.endDate.reset();
    this.newSelectsData.date.end = "";
    this.selectService.updateSelectsData(this.newSelectsData);
  }

  public onKeyUp(inputCategory: string, value: any) {
    clearTimeout(this.timeout);
    var self = this;

    this.timeout = setTimeout(function () {

      switch (inputCategory) {
        case 'folderId':
          self.newSelectsData.folderId = value;
          break;
        case 'boxId':
          self.newSelectsData.boxId = value;
          break;
        case 'message':
          self.newSelectsData.message = value;
          break;
      }

      self.selectService.updateSelectsData(self.newSelectsData);
    }, 1000);


  }

  onChange(selectCategory: string, selectValue: unknown) {
    const selectValueString = selectValue as string;

    switch (selectCategory) {
      case "projects":
        this.newSelectsData.project = selectValueString;
        break;

      case "types":
        this.newSelectsData.type = selectValueString;
        break;

      case "services":
        this.newSelectsData.service = selectValueString;
        break;

      case "dateStart":
        let startAux = Math.round(moment(selectValueString).utc().valueOf() / 1000);
        const dateStartInUTC = startAux.toString();
        this.newSelectsData.date.start = dateStartInUTC;
        break;

      case "dateEnd":
        let EndAux = Math.round(moment(selectValueString).utc().valueOf() / 1000);
        const dateEndInUTC = EndAux.toString();
        this.newSelectsData.date.end = dateEndInUTC;
        break

      default:
        break;
    }

    this.selectService.updateSelectsData(this.newSelectsData);
  }

}
