import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { selectsData } from 'src/app/interfaces/selectsData';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {

  private selectsData: BehaviorSubject<selectsData>;

  constructor(private http: HttpClient) {
    const initialData: selectsData =
      localStorage.getItem('selectsData')
        ? JSON.parse(localStorage.getItem('selectsData')!)
        : {
          project: '',
          service: '',
          type: '',
          boxId: '',
          folderId: '',
          date: {
            start: Math.round(moment().subtract(5, 'days').utc().valueOf() / 1000),
            end: Math.round(moment().utc().valueOf() / 1000)
          }
        }

    this.selectsData = new BehaviorSubject<selectsData>(initialData);
  }

  updateSelectsData(newSelectsData: selectsData) {
    this.selectsData.next(newSelectsData);
    this.saveData();
  }

  getSelectsData(): Observable<selectsData> {
    return this.selectsData.asObservable();
  }

  private saveData() {
    const dataString: string = JSON.stringify(this.selectsData.getValue());
    localStorage.setItem('selectsData', dataString);
  }
}
